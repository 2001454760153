import { Component } from '@angular/core';

@Component({
  selector: 'app-companydocuments',
  templateUrl: './companydocuments.component.html',
  styleUrl: './companydocuments.component.css'
})
export class CompanydocumentsComponent {

}
