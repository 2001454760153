import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MytimeComponent } from './mytime/mytime.component';
import { RequesttimeComponent } from './requesttime/requesttime.component';
import { PeoplestimeComponent } from './peoplestime/peoplestime.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { RequestchangeComponent } from './requestchange/requestchange.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'requestchange', component: RequestchangeComponent },
  { path: 'peoplestime', component: PeoplestimeComponent }

];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }]
})
export class AppRoutingModule { }
