import { Component, OnInit, EventEmitter,Output } from '@angular/core';
import { DataService } from '../data.service';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrl: './balances.component.css'
})
export class BalancesComponent {

  constructor(private dataService: DataService, private route: ActivatedRoute) { }

  UserID: number = 0;
  dataBalancesForUser: any[] = [];


  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      // Access query parameters here
      if (params['UserID']) {
        this.UserID = params['UserID'];    // Access the userId query parameter
      }
    });


    if (this.dataService.GlobalIsAdmin && this.UserID !=0) {
      this.dataService.dbAdminGetBalancesForUser(this.UserID).subscribe((response) => {
        this.dataBalancesForUser = response.dsData;
      });
    } else {
      this.dataService.dbGetBalancesForUser().subscribe((response) => {
        this.dataBalancesForUser = response.dsData;
      });
    }


  }




}
