import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment'; // Correct import path


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  
  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/api/auth/login`,
      { email, password },
      { withCredentials: true }  // Include cookies in the request
    );
  }

  logout(): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/api/auth/logout`,
      {},
      { withCredentials: true }  // Include cookies in the request
    );
  }




}
