import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd  } from '@angular/router';
import { AuthService } from '../services/auth.service';  // Import your AuthService
import { DataService } from '../data.service';
import { GlobalService } from '../global.service'; 
import { MenusmallComponent } from '../menusmall/menusmall.component';
import { MatSidenav } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { SidepanelcontentComponent } from '../sidepanelcontent/sidepanelcontent.component';
import { PendingapprovalsComponent } from '../pendingapprovals/pendingapprovals.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';
import { environment } from '../../environments/environment'; // Correct import path
import { Location } from '@angular/common';
import { PinboardComponent } from '../pinboard/pinboard.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.css'
})
export class DashboardComponent implements AfterViewInit {
  constructor(private location: Location,private cdr: ChangeDetectorRef,private route: ActivatedRoute, private router: Router, private authService: AuthService, private dataService: DataService, private globalService: GlobalService, private _bottomSheet: MatBottomSheet) { }

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild(SidepanelcontentComponent) sidePanelContent: SidepanelcontentComponent | undefined;

  headercolor: string = '#444';


  ngAfterViewInit(): void {
    this.headercolor = environment.headercolor;


  }


  DisplayName: string = "";
  IsAdmin: number = 0;
  IsApprover: number = 0;
  dataMyTime: any;

  menuOption: string = "mytime!";
  isHovered = false;
  public globalFragment: string | null = null;
  private previousUrl: string = '';

  ishashsame: boolean = false;


  async ngOnInit() {

    //listen for event
    this.dataService.SidePanelOperationEvent$.subscribe(() => {
      this.sidenav.close();
    });

    //listen for event
    this.dataService.SidePanelOpenEvent$.subscribe(data => {
      this.sidePanelContent?.loadRequest(data);
      this.sidenav.open();
    });


    this.DisplayName = this.dataService.GlobalName;
    this.IsAdmin = this.dataService.GlobalIsAdmin;
    this.IsApprover = this.dataService.GlobalIsApprover;

    await this.globalService.GetSystemData();


    this.route.fragment.subscribe(fragment => {
      console.log('Load from Hash:', fragment);
      this.globalFragment = fragment;

     this.loadComponentBasedOnHash(fragment);
    });


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.urlAfterRedirects;
        if (this.ishashsame && (currentUrl != this.previousUrl)) {
          console.log('URL changed, reloading the page...');

          var savmenu = this.menuOption;
          this.menuOption = "";
          setTimeout(() => {
            this.menuOption = savmenu;; // Recreate the child component
          }, 0);
        }

        // Update the previous URL to the current one
        this.previousUrl = currentUrl;
      }
    });

    this.route.queryParams.subscribe(params => {
      if (this.location.path(true).split('#')[1] == this.globalFragment) {
        this.ishashsame = true;
      } else {
        this.ishashsame = false;
      }
    });



  }




  loadComponentBasedOnHash(fragment: string | null) {

    this.menuOption = fragment || "";
  }

  Logout() {
    this.authService.logout().subscribe({
      next: () => this.router.navigate(['/login'])
    });
  }


  closeSidenav(): void {
    this.sidenav.close();
  }

}
