<h1>My Time Off {{UserName}}</h1>

<mat-tab-group class="layout2">
  <mat-tab label="Balance">
    <app-balances></app-balances>
  </mat-tab>
  <mat-tab label="All Requests">
    <app-requests></app-requests>
  </mat-tab>
</mat-tab-group>

