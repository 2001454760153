
<div #tooltipContainer></div>

  <div id="pinboardHeader">
    <div id="pnlcontrols">
      <div style="display:flex;">
        <mat-form-field style="width: 130px; height: 76px;">
          <mat-label>Date Scope</mat-label>
          <mat-select [(value)]="selectedDateScope" (selectionChange)="onSelectedDateScope($event)">
            <mat-option value="week">Week</mat-option>
            <mat-option value="2weeks">2 Weeks</mat-option>
            <mat-option value="month">Month</mat-option>
          </mat-select>
        </mat-form-field>
        <div id="monthnav" style="display: flex; align-items: center; justify-content: center; gap: 10px;">
          <button class="btn" (click)="btnPrevScope()">
            <span class="material-symbols-outlined">arrow_back_ios</span>
          </button>
          <div style="display: inline-block; vertical-align: top;">
            {{ DateScopeDisplay }}
          </div>
          <button class="btn" (click)="btnNextScope()">
            <span class="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>
      </div>
    </div>
  </div>


  <div class="scrollable-table">
    <table id="mytable" mat-table [dataSource]="rowData" class="mat-elevation-z8">

      <ng-container matColumnDef="Name">
        <th class="nameheader sticky-header" mat-header-cell *matHeaderCellDef> Employees </th>
        <td class="namecontent" [ngClass]="{'isadmin': isAdmin == 1}" (click)="isAdmin == 1 ? showUserDashboard(row?.UserID) : null" class="namecell" mat-cell *matCellDef="let row">{{row?.DisplayName }} </td>
      </ng-container>

      <ng-container *ngFor="let column of displayedColumns.slice(1)" [matColumnDef]="column">
        <th class="sticky-header" id="a{{ column | date: 'ddMMMyyyy' }}" mat-header-cell *matHeaderCellDef>{{ column | date:'dd' }}<br />{{ column | date: 'EEE' }} </th>
        <td [ngClass]="{'weekend-cell': row.UserRow[displayedColumns.indexOf(column) - 1]?.isWeekend,'bh-cell': row.UserRow[displayedColumns.indexOf(column) - 1]?.isBankHoliday}" mat-cell *matCellDef="let row" style="padding: 0; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
          <!-- Cell content goes here (e.g., data or other components) -->
        </td>
      </ng-container>

      <!-- Header and Rows -->
      <tr class='sticky' mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr style="height:{{row.rowheight}}px!important;" [id]="row.UserID"  [ngClass]="{'sticky': row?.UserID == 'UserID9000'}" [ngStyle]="{'top' : row?.UserID == 'UserID9000' ? '51px' : '0px'}" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>




