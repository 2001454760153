<div class="login-container {{dev}}">



  <mat-card class="login-card" *ngIf="!showConfirm">


    <mat-card-header class="login-header">
      <mat-card-title class="title">Forgot Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Username/Email</mat-label>
          <input matInput formControlName="emailaddress" placeholder="Enter your username/email address" #usernameInput required />
          <mat-icon matPrefix>person</mat-icon>
        </mat-form-field>

        <div class="login-actions">
          <button mat-raised-button color="primary" [disabled]="loginForm.invalid">Send</button>
        </div>
      </form>



      <a class="forgot-password" href="#login">Back to Login</a>
    </mat-card-content>
  </mat-card>


  <mat-card class="login-card" *ngIf="showConfirm">
    A password reset link was sent to your email address.<br /><br />When received, please click the link to complete the reset procedure.
    <a class="forgot-password" [routerLink]="['/login']">Back to Login</a>
  </mat-card>


</div>

<div *ngIf="showConfirm">
  A password reset link was sent to your email address.  When received, please click the link to complete the reset procedure.
</div>
