import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthGuard } from '../auth.guard';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authGuard: AuthGuard) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    const authToken = this.authGuard.getAuthToken();

    const authReq = req.clone({
      headers: req.headers.set('Authorization',`Bearer ${authToken}`)
    });

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error caught by interceptor:', error);  // Check if error is caught

        if (error.status === 401) {
          console.log('401 Unauthorized detected - navigating to login');
          this.router.navigate(['/login']);  // Navigate to login page on 401
        }

        return throwError(error);  // Continue error propagation
      })
    );
  }
}
