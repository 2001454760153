  <div *ngFor="let item of dataBalancesForUser" class="pnlAbsenceRow">
    <mat-accordion>
      <mat-expansion-panel class="height:auto;" hideToggle class="mat-elevation-z0">
        <mat-expansion-panel-header style="flex:1;height:auto;" collapsedHeight="70px" expandedHeight="70px">

          <div class="header-content">

            <div class="pnlAbsenceContent">
              <div class="absenceKey" [ngStyle]="{ 'background-color': item.AbsenceBackColour }"></div>
            </div>





            <div class="pnlCounters">
              <div style=" display: flex; align-items: center; justify-content: flex-start; flex-direction: row;min-width:205px;">
                <div stlye="display: flex;">
                    <div class="">{{ item.AbsenceDescription }}</div>
                </div>
              </div>

              <div style="display: flex; flex-direction: row; flex-wrap: wrap; align-items: center; justify-content: flex-start;">

                <div class="pnlPeriodContent">

                  <div class="periodtitle">2025</div>
                  <!-- Current Balance -->
                  <div *ngIf="item.Allocated2025 > 0" class="absence-column entitlement">
                    <div class="current-balance">{{ item.Allocated2025 }}</div>
                    <div class="current-balance-label">Entitlement</div>
                  </div>

                  <div *ngIf="item.Balance2025 > 0" class="absence-column balance">
                    <div class="current-balance">{{ item.Balance2025 }}</div>
                    <div class="current-balance-label">Current balance</div>
                  </div>

                  <!-- Hours Taken (only if it's not zero) -->
                  <div class="absence-column hours">
                    <div class="hours-taken">{{ item.HoursTaken2025 }}</div>
                    <div class="hours-taken-label">Hours taken</div>
                  </div>
                </div>



                <div class="pnlPeriodContent">
                  <div class="periodtitle">2024</div>

                  <!-- Entitlement -->
                  <div *ngIf="item.Allocated2024 > 0" class="absence-column entitlement">
                    <div class="current-balance">{{ item.Allocated2024 }}</div>
                    <div class="current-balance-label">Entitlement</div>
                  </div>

                  <!-- Current Balance -->
                  <div *ngIf="item.Balance2024 > 0" class="absence-column balance">
                    <div class="current-balance">{{ item.Balance2024 }}</div>
                    <div class="current-balance-label">Current balance</div>
                  </div>

                  <!-- Hours Taken (only if it's not zero) -->
                  <div class="absence-column hours">
                    <div class="hours-taken">{{ item.HoursTaken2024 }}</div>
                    <div class="hours-taken-label">Hours taken</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </mat-expansion-panel-header>

        <div class="panel-content">
          <app-requests [AbsenceID]="item.AbsenceID" [ItemsPerPage]="5"></app-requests>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

