import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { format, parseISO, isValid } from 'date-fns';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';  // Import FormsModule
import moment from 'moment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerToggle } from '@angular/material/datepicker';
import { GlobalService } from '../global.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';
import { Observable } from 'rxjs';
import { firstValueFrom } from 'rxjs';

interface BankHoliday {
  BHDate: string; // Define the expected structure
}


function isValidDate(dateString: string): boolean {
  const date = parseISO(dateString);
  return isValid(date);
}



@Component({
  selector: 'app-requesttime',
  templateUrl: './requesttime.component.html',
  styleUrl: './requesttime.component.css'
})
export class RequesttimeComponent implements OnInit {

  @Input() isManaging: boolean = false;  // Data received from the parent

  constructor(private dataService: DataService, private router: Router, public dialog: MatDialog, private fb: FormBuilder, private globalService: GlobalService, private cdr: ChangeDetectorRef, private _bottomSheet: MatBottomSheet) {}


  isBusy: boolean = false;


  yourForm!: FormGroup;

  minStartDate: moment.Moment = moment();
  minEndDate: moment.Moment = moment();

  title: string = 'Request or Report Time Off';

  StartDurations = [
    { DurationID: 4, DurationDescription: 'All Day' },
    { DurationID: 2, DurationDescription: 'Afternoon' },
    { DurationID: 3, DurationDescription: 'Custom Time' }
  ];

  EndDurations = [
    { DurationID: 4, DurationDescription: 'All Day' },
    { DurationID: 1, DurationDescription: 'Morning' },
    { DurationID: 3, DurationDescription: 'Custom Time' }
  ];
  SameDayDurations = [
    { DurationID: 4, DurationDescription: 'All Day' },
    { DurationID: 1, DurationDescription: 'Morning' },
    { DurationID: 2, DurationDescription: 'Afternoon' },
    { DurationID: 3, DurationDescription: 'Custom Time' }
  ];


  disabledMinutes!: any[];

  // currentYear: string = moment().year().toString();
  SelectedUserID!: string;
  SelectedAbsenceID!: string;
  StartDuration!: number;
  EndDuration!: number;
  StartCustomHour!: string
  StartCustomMinute!: string
  EndCustomHour!: string
  EndCustomMinute!: string

  dataAbsenceTypes: any;
  isValid: boolean | null = null;

  filteredStartMinutes: any[] = [];
  filteredEndMinutes: any[] = [];
  dataUsers: any[] = [];
 // dataHours: any[] = [];
  dataStartHours: any[] = [];
  dataEndHours: any[] = [];
  dataMinutes: any[] = [];
  dataBankHolidays: moment.Moment[] = [];

  dataRequestTypes: any[] = [];
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;

  isAutoApproved = false; // The default value

  submitDisabledReason: string = "";

  get selectedAbsenceDescription(): string {
    const selectedOption = this.dataRequestTypes.find(option => option.AbsenceID === this.SelectedAbsenceID);
    return selectedOption ? selectedOption.AbsenceDescription : 'None';
  }


  FilterWeekends = (date: moment.Moment | null): boolean => {
    // Convert the date to a Moment object
    const momentDate = moment(date);
    if (!momentDate.isValid()) {
      return true; // Allow selection if it's not a valid date
    }

    var toshow: boolean = true;
    var bankhol: boolean = true;

    //weekends
    const day = momentDate.day();
    if (day == 0) {
      toshow = false;
    }
    if (day == 6) {
      toshow = false;
    }

    //bank holidays
    bankhol = !this.dataBankHolidays.some(holiday => holiday.isSame(date, 'day'));
    if (bankhol === false) toshow = false;

    //allowed bookable range
    var AllowedStartHolidayPeriod = this.globalService.dataPeriod[0].PeriodStart;
    var AllowedEndHolidayPeriod = this.globalService.dataPeriod[0].StaffBookableEndDate;
    if (momentDate.isBefore(moment(AllowedStartHolidayPeriod))) {
    return false;  // Disable dates before the allowed start period
    }
    if (momentDate.isAfter(moment(AllowedEndHolidayPeriod))) {
      return false;  // Disable dates after the allowed end period
    }
    return toshow;

  };


  disableButton: boolean = false;


  async dbGetDateConflicts(blnEnable: boolean): Promise<boolean> {


    var RequestUserID: number = 0

    if (this.isManaging) {
      RequestUserID = parseInt(this.SelectedUserID);
    } else {
      RequestUserID = this.dataService.GlobalUserID;
    }
    const RequestStartDate = moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY');
    const RequestEndDate = moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY');
    const RequestAbsenceID = this.yourForm.get('frmSelectedAbsenceID')?.value;


    if (!isValidDate(RequestStartDate) || !isValidDate(RequestEndDate)) {
      return blnEnable
    };




    const data: any = await firstValueFrom(this.dataService.dbGetDateConflicts(
      RequestUserID,
      RequestStartDate,
      RequestEndDate,
      RequestAbsenceID
    ));


    if (data.dsData[0].hasConflict === 1) {
      this.submitDisabledReason = 'Date conflicts with another absence already requested.'
      blnEnable = true;
      return true;
    }


    return blnEnable; //pass on to next check
  }

  

  async CheckEntitlement(blnEnable: boolean): Promise<boolean> {
    //return true if ok





    var RequestUserID: number = 0

    if (this.isManaging) {
      RequestUserID = parseInt(this.SelectedUserID);
    } else {
      RequestUserID = this.dataService.GlobalUserID;
    }

    var AbsenceID: number = this.yourForm.get('frmSelectedAbsenceID')?.value;
    const RequestStartDate = moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY');
    const RequestEndDate = moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY');
    var StartDuration1: number = this.yourForm.get('StartDuration1')?.value;
    var EndDuration1: number = this.yourForm.get('EndDuration1')?.value;
    var StartCustomTime = this.yourForm.get('StartCustomHour1')?.value + ':' + this.yourForm.get('StartCustomMinute1')?.value;
    var EndCustomTime = this.yourForm.get('EndCustomHour1')?.value + ':' + this.yourForm.get('EndCustomMinute1')?.value;

    if (RequestStartDate === RequestEndDate) {
      //Because we are using the endduration inforatmion when the dates are the same date...
      //we need to assume that all the end durations are the correct inforamtion so we need to copy the end duration field to the start duration field
      StartDuration1 = EndDuration1
    }


    //console.log(isValidDate(RequestStartDate));
    //if (!isValidDate(RequestStartDate) || !isValidDate(RequestEndDate)) {
    //  return blnEnable
    //};



    const data = await firstValueFrom(this.dataService.dbGetBalanceAfterApproved(
        RequestUserID,
        RequestStartDate,
        RequestEndDate,
        StartDuration1,
        EndDuration1,
        StartCustomTime,
        EndCustomTime,
        AbsenceID
      ));


    console.log(data.dsData[0].BalanceAfterApproved)

      if (data.dsData[0].BalanceAfterApproved < 0 && data.dsData[0].isConstrainedToEntitlement == 1) {
        this.submitDisabledReason = "You do not have enough entitlement, you have " + data.dsData[0].CurrentBalance + ' hours left.'
        blnEnable = true;//disable button
        return true;
      }

      return blnEnable; 

      }


 


  private endDateMinValidator(control: any, startDate: string): { [key: string]: boolean } | null {
    const endDate = control.value;
    if (endDate && new Date(endDate) < new Date(startDate)) {
      return { 'endDateBeforeStartDate': true }; // Return error if endDate is before startDate
    }
    return null; // Valid if no error
  }


  LoadUserTimes(): void {

    if (this.isManaging === true) {
      this.dataService.dbAdminGetUserHours(moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY'), this.SelectedUserID).subscribe(
        dataHours => {
          this.dataStartHours = dataHours.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      )

      this.dataService.dbAdminGetUserHours(moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY'), this.SelectedUserID).subscribe(
        dataHours => {
          this.dataEndHours = dataHours.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      )

    } else {


      this.dataService.dbGetUserHours(moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY')).subscribe(
        dataHours => {
          this.dataStartHours = dataHours.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      )

      this.dataService.dbGetUserHours(moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY')).subscribe(
        dataHours => {
          this.dataEndHours = dataHours.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      )


    }


  }


  async verifyForm(): Promise<void> {


    var blnEnable: boolean = false


    var AbsenceID: number = this.yourForm.get('frmSelectedAbsenceID')?.value;
    const RequestStartDate = moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY');
    const RequestEndDate = moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY');
    var StartDuration1: number = this.yourForm.get('StartDuration1')?.value;
    var EndDuration1: number = this.yourForm.get('EndDuration1')?.value;
    var StartCustomTime = this.yourForm.get('StartCustomHour1')?.value + ':' + this.yourForm.get('StartCustomMinute1')?.value;
    var EndCustomTime = this.yourForm.get('EndCustomHour1')?.value + ':' + this.yourForm.get('EndCustomMinute1')?.value;
    const StartDateTime = moment(`${RequestStartDate} ${StartCustomTime}`, 'DD MMM YYYY HH:mm');
    const EndDateTime = moment(`${RequestEndDate} ${EndCustomTime}`, 'DD MMM YYYY HH:mm');

    //console.log(StartDateTime)
    //console.log(EndDateTime)

    if (RequestStartDate === RequestEndDate) {
      //Because we are using the endduration inforatmion when the dates are the same date...
      //we need to assume that all the end durations are the correct inforamtion so we need to copy the end duration field to the start duration field
      StartDuration1 = EndDuration1
    }


    if (StartDuration1 === 3) { //custom time
      if (StartCustomTime === '00:00') {
        blnEnable = true;
      }
      if (EndDateTime.isBefore(StartDateTime)) {
        blnEnable = true; //not
      }

      if (StartDateTime.isSame(EndDateTime)) {
        blnEnable = true; //not
      }
    }

    if (EndDuration1 === 3) { //custom time
      if (EndCustomTime === '00:00') {
        blnEnable = true;
      }
      if (EndDateTime.isBefore(StartDateTime)) {
        blnEnable = true; //not
      }

      if (StartDateTime.isSame(EndDateTime)) {
        blnEnable = true; //not
      }


    }

    this.submitDisabledReason = '';

    blnEnable = await this.CheckEntitlement(blnEnable);
    blnEnable = await this.dbGetDateConflicts(blnEnable);


    this.disableButton = blnEnable;//true=disabled

  }



  CheckCustomTimes(): void {

    var StartDuration1: number = this.yourForm.get('StartDuration1')?.value;
    var EndDuration1: number = this.yourForm.get('EndDuration1')?.value;

    //make the end date filter prior dates
    this.minEndDate = moment(this.yourForm.get('frmStartDate')?.value);
    if (this.yourForm.get('frmEndDate')?.value < this.yourForm.get('frmStartDate')?.value) {
      this.yourForm.get('frmEndDate')?.setValue(moment(this.yourForm.get('frmStartDate')?.value));
    }

    if (StartDuration1 === 3) {
      console.log('is custom start');
      this.LoadUserTimes();
    }

    if (EndDuration1 === 3) {
      console.log('is custom end');
      this.LoadUserTimes();
    }

  }






  ngOnInit(): void {

    if (this.isManaging === true) {
      this.title = 'Manage Absence Requests';
      this.isAutoApproved = true;
      this.minStartDate = moment().subtract(1, 'year');
    }





    this.yourForm = this.fb.group({
      frmSelectedAbsenceID: ['', Validators.required], 
      frmStartDate: [null, Validators.required], 
      frmEndDate: [null, Validators.required], 
      StartDuration1: [null, Validators.required],  
      EndDuration1: [null, Validators.required],  
      StartCustomHour1: [null],  
      StartCustomMinute1: [null],
      EndCustomHour1: [null],   
      EndCustomMinute1: [null],
      Notes1: [''],
      frmSelectedUserID:[null]
    });



    this.yourForm.get('frmSelectedUserID')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();
    });

    this.yourForm.get('frmSelectedAbsenceID')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();
    });

    this.yourForm.get('frmStartDate')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();
    });

    this.yourForm.get('frmEndDate')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();
    });

    this.yourForm.get('StartDuration1')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();

    });

    this.yourForm.get('EndDuration1')?.valueChanges.subscribe(startDate => {
      this.CheckCustomTimes();
      this.verifyForm();
    });

    this.yourForm.get('StartCustomHour1')?.valueChanges.subscribe(startDate => {

      this.CheckCustomTimes();
 

      //check if the hour select is the first in the list, if so, make sure the minuites are correct
      const firstItemValue = this.dataStartHours[0]?.Hour;
      const lastItemValue = this.dataStartHours[this.dataStartHours.length-1]?.Hour;

      if (startDate === firstItemValue) {
        this.yourForm.get('StartCustomMinute1')?.setValue(this.dataStartHours[0]?.mn);
        this.filteredStartMinutes = this.dataMinutes.filter(option => option.Minute >= this.dataStartHours[0]?.mn);
      } else if (startDate === lastItemValue) {
        //this.filteredStartMinutes = this.dataMinutes.filter(option => option.Minute <= this.dataStartHours[0]?.mn);
        //this.yourForm.get('StartCustomMinute1')?.setValue(this.dataStartHours[this.dataStartHours.length - 1]?.mn);
        this.filteredStartMinutes = this.dataMinutes.filter(option => option.Minute <= this.dataStartHours[this.dataStartHours.length - 1]?.mn);
        this.yourForm.get('StartCustomMinute1')?.setValue(this.dataStartHours[0]?.mn);

      } else {
        this.filteredStartMinutes = this.dataMinutes;
      }

      this.verifyForm();
    });

    this.yourForm.get('EndCustomHour1')?.valueChanges.subscribe(EndDate => {

      this.CheckCustomTimes();
  
      //check if the hour select is the first in the list, if so, make sure the minuites are correct
      const firstItemValue = this.dataEndHours[0]?.Hour;
      const lastItemValue = this.dataEndHours[this.dataEndHours.length - 1]?.Hour;



     

      if (EndDate === firstItemValue) {
        
        this.yourForm.get('EndCustomMinute1')?.setValue(this.dataEndHours[0]?.mn);
        this.filteredEndMinutes = this.dataMinutes.filter(option => option.Minute >= this.dataEndHours[0]?.mn);
      } else if (EndDate === lastItemValue) {
        this.filteredEndMinutes = this.dataMinutes.filter(option => option.Minute <= this.dataEndHours[this.dataEndHours.length - 1]?.mn);
        this.yourForm.get('EndCustomMinute1')?.setValue(this.dataEndHours[0]?.mn);

      } else {
        this.filteredEndMinutes = this.dataMinutes;
      }


      this.verifyForm();

    });


    this.yourForm.get('StartCustomMinute1')?.valueChanges.subscribe(startDate => {
      this.verifyForm();
    });

    this.yourForm.get('EndCustomMinute1')?.valueChanges.subscribe(startDate => {
      this.verifyForm();
    });


    if (this.isManaging === true) {

      this.dataService.dbAdminGetAllAbsenceTypes().subscribe(
        dataAbsenceTypes => {
          this.dataRequestTypes = dataAbsenceTypes.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      );



    } else {

      this.dataService.dbGetAbsenceTypesForUser().subscribe(
        dataAbsenceTypes => {
          this.dataRequestTypes = dataAbsenceTypes.dsData;
        },
        error => {
          console.error('Error fetching options:', error);
        }
      );

    }


    this.dataService.dbGetBankHolidays('2024').subscribe(
      dataBankHolidays => {
        const bankHolidays: BankHoliday[] = dataBankHolidays.dsData;
        this.dataBankHolidays = bankHolidays.map(item => moment(item.BHDate, 'DD MMM YYYY'));
      },
      error => {
        console.error('Error fetching options:', error);
      }
    );

    this.dataService.dbAdminGetAssignedUsers().subscribe(
      dataUsers=> {
        this.dataUsers = dataUsers.dsData;
      },
      error => {
        console.error('Error fetching options:', error);
      }
    );



    this.dataService.dbGetMinutes().subscribe(
      dataMinutes => {
        this.dataMinutes = dataMinutes.dsData;
      },
      error => {
        console.error('Error fetching options:', error);
      }
    );



    this.yourForm.get('StartDuration1')?.setValue(this.StartDurations[0].DurationID);
    this.yourForm.get('EndDuration1')?.setValue(this.EndDurations[0].DurationID);
    this.yourForm.get('StartCustomHour1')?.setValue('00');
    this.yourForm.get('StartCustomMinute1')?.setValue('00');
    this.yourForm.get('EndCustomHour1')?.setValue('00');
    this.yourForm.get('EndCustomMinute1')?.setValue('00');


  }







  datesAreEqual(): boolean {
    const frmStartDate = this.yourForm.get('frmStartDate')?.value;
    const frmEndDate = this.yourForm.get('frmEndDate')?.value;

    if (frmStartDate && frmEndDate) {
      return moment(frmStartDate).isSame(frmEndDate, 'day');
    }
    return false;
  }


  onSubmit(): void {


    if (this.yourForm.valid) {

      this.isBusy = true;

      //this.cdr.detectChanges();

      const formattedStartDate = moment(this.yourForm.get('frmStartDate')?.value).format('DD MMM YYYY');
      const formattedEndDate = moment(this.yourForm.get('frmEndDate')?.value).format('DD MMM YYYY');


      var pStartDuration = this.yourForm.get('StartDuration1')?.value;
      var pStartCustomTime = this.yourForm.get('StartCustomHour1')?.value + ':' + this.yourForm.get('StartCustomMinute1')?.value;
      var pEndDuration = this.yourForm.get('EndDuration1')?.value;
      var pEndCustomTime = this.yourForm.get('EndCustomHour1')?.value + ':' + this.yourForm.get('EndCustomMinute1')?.value;
      // var pNotes = this.yourForm.get('Notes1')?.value;


     // var pNotes = encodeURIComponent((this.yourForm.get('Notes1')?.value).replace(/[^a-zA-Z0-9., -]/g, ''));
     // var pNotes = encodeURIComponent(this.yourForm.get('Notes1')?.value).replace(/%/g, '');
      
      var pNotes = encodeURIComponent(this.yourForm.get('Notes1')?.value.replace(/%/g, '').replace(/\\/g, '').replace(/(\r\n|\n|\r)/g, ' '));
      
      if (formattedStartDate === formattedEndDate) {
        //Because we are using the endduration inforatmion when the dates are the same date...
        //we need to assume that all the end durations are the correct inforamtion so we need to copy the end duration field to the start duration field
        pStartDuration = pEndDuration
      }




 
      var SaveUserID: string = this.dataService.GlobalUserID.toString();




      //if thisis the manage users times screen - override the userid
      if (this.isManaging === true) {;
        SaveUserID = this.SelectedUserID;
      }
    
      //save to db
      const data = { AbsenceID: this.yourForm.get('frmSelectedAbsenceID')?.value, UserID: SaveUserID, DateFrom: formattedStartDate, DateTo: formattedEndDate, Notes: pNotes, StartDuration: pStartDuration, StartCustomTime: pStartCustomTime, EndDuration: pEndDuration, EndCustomTime: pEndCustomTime, AutoApprove: this.isAutoApproved ? 1 : 0 };
  


      this._bottomSheet.open(BottomsheetComponent, {
        data: { message: 'Request has been submitted!' }
      });

      this.dataService.dbSaveRequest(data).subscribe(
        response => {
          this.router.navigate(['/dashboard'], { fragment: 'mytime' });
        },
        error => {
          console.error('Error:', error);
        }
      );






    } else {
      console.log('Form is not valid');
    }
  }






}
