<nav>
  <ul>
    <li><a [routerLink]="['/dashboard']" [fragment]="'mytime'"><mat-icon>event_available</mat-icon><span>My Time Off</span></a></li>
    <li><a [routerLink]="['/dashboard']" [fragment]="'requesttime'"><mat-icon>schedule</mat-icon><span>Request or Report Time Off</span></a></li>
    <li><a [routerLink]="['/dashboard']" [fragment]="'peoplestime'"><mat-icon class="material-symbols-outlined">calendar_clock</mat-icon><span>Calendar View</span></a></li>
    <li><a href="/" (click)="Logout()"><mat-icon>logout</mat-icon><span>Sign Out</span></a></li>

    <div *ngIf="IsAdmin == 1 || IsApprover == 1">
      <hr />
      <br />
    <li *ngIf="IsAdmin == 1"><a [routerLink]="['/dashboard']" [fragment]="'usermaintenance'"> <mat-icon>group</mat-icon><span>Users</span></a></li>
    <li *ngIf="IsAdmin == 1 || IsApprover == 1"><a [routerLink]="['/dashboard']" [fragment]="'pendingapprovals'"><mat-icon>date_range</mat-icon><span>Pending Approvals</span></a></li>
    <li *ngIf="IsAdmin == 1"><a [routerLink]="['/dashboard']" [fragment]="'manageuserstime'"><mat-icon>manage_accounts</mat-icon><span>Manage Users Absence</span></a></li>
    </div>
  </ul>
</nav>



<!--
<nav >
  <ul>
    <li><a href="/#/dashboard/#mytime"><mat-icon>event_available</mat-icon><span>My Time Off</span></a></li>
    <li><a href="/#/dashboard/#requesttime"><mat-icon>schedule</mat-icon><span>Request or Report Time Off</span></a></li>
    <li><a href="/#/dashboard/#peoplestime"><mat-icon class="material-symbols-outlined">calendar_clock</mat-icon><span>Calendar View</span></a></li>
    <li><a href="/" (click)="Logout()"><mat-icon>logout</mat-icon><span>Sign Out</span></a></li>

    <div *ngIf="IsAdmin == 1 || IsApprover == 1">
      <hr />
      <br />
      <li *ngIf="IsAdmin == 1"><a href="/#/dashboard/#usermaintenance"> <mat-icon>group</mat-icon><span>Users</span></a></li>
    <li *ngIf="IsAdmin == 1 || IsApprover == 1"><a href="/#/dashboard#pendingapprovals"><mat-icon>date_range</mat-icon><span>Pending Approvals</span></a></li>
    <li *ngIf="IsAdmin == 1"><a href="/#/dashboard/#manageuserstime"><mat-icon>manage_accounts</mat-icon><span>Manage Users Absence</span></a></li>
    </div>
  </ul>
</nav>


-->
