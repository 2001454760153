
<h1>{{title}}</h1>

<div class="scrollable-content2">

  <form [formGroup]="yourForm" (ngSubmit)="onSubmit()">

    <mat-form-field *ngIf="isManaging" style="display:flex;min-width:270px;width: fit-content;">
      <mat-label>Select Employee</mat-label>
      <mat-select [(value)]="SelectedUserID" formControlName="frmSelectedUserID">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let option of dataUsers" [value]="option.UserID">
          {{option.DisplayName}}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field style="min-width:270px;width: fit-content;">
      <mat-label>Select Absence Type</mat-label>
      <mat-select [(value)]="SelectedAbsenceID" formControlName="frmSelectedAbsenceID">

        <mat-select-trigger>
          {{ selectedAbsenceDescription }}
        </mat-select-trigger>

        <mat-option>None</mat-option>
        <mat-option *ngFor="let option of dataRequestTypes" [value]="option.AbsenceID">
          <div style="display: flex; align-items: center; flex-direction: row; align-content: center; justify-content: flex-start;"><span style="margin-right:10px;" class="material-symbols-outlined">{{option.Icon}}</span>{{option.AbsenceDescription}}</div>
          </mat-option>
</mat-select>
      <mat-error *ngIf="yourForm.controls['frmSelectedAbsenceID'].hasError('required')">
        Required
      </mat-error>
    </mat-form-field>


    <br />


    <label for="dateTimeFrom">Date and Time From:</label>



    <!--START DATE-->
    <mat-form-field style="width:160px;">
      <mat-label>Start Date</mat-label>
      <input (click)="startDate.open()" matInput [matDatepickerFilter]="FilterWeekends" [matDatepicker]="startDate" name="selectedStartDate" formControlName="frmStartDate" [min]="minStartDate" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="startDate">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #startDate disabled="false"></mat-datepicker>
      <mat-error *ngIf="yourForm.controls['frmStartDate'].hasError('required') && yourForm.controls['frmStartDate'].touched">
        Start Date is required
      </mat-error>
    </mat-form-field>


    <div *ngIf="yourForm.controls['frmStartDate'].value && yourForm.controls['frmEndDate'].value && !datesAreEqual()">

      <mat-form-field style="width:160px;">
        <!--Start Duration-->
        <mat-label>Duration1</mat-label>
        <mat-select [(value)]="StartDuration" formControlName="StartDuration1">
          <mat-option *ngFor="let option of StartDurations" [value]="option.DurationID">
            {{option.DurationDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="StartDuration === 3">
        <!--custom-->
        <mat-form-field style="width: 120px;">
          <mat-label>Start Hour</mat-label>
          <mat-select formControlName="StartCustomHour1">
            <mat-option *ngFor="let option of dataStartHours" [value]="option.Hour">
              {{option.Hour}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="yourForm.controls['StartDuration1'].value === 3">
            Hour Required
          </mat-error>
        </mat-form-field>
        <mat-form-field style="width: 130px;">
          <mat-label>Start Minute</mat-label>
          <mat-select formControlName="StartCustomMinute1">
            <mat-option *ngFor="let option of filteredStartMinutes" [value]="option.Minute">
              {{option.Minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <br />

    <!--END DATE-->
    <mat-form-field style="width:160px;">
      <mat-label>End Date</mat-label>
      <input (click)="endDate.open()" matInput [matDatepicker]="endDate" [matDatepickerFilter]="FilterWeekends" name="selectedEndDate" formControlName="frmEndDate" [min]="minEndDate" readonly>
      <mat-datepicker-toggle matIconSuffix [for]="endDate">
        <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #endDate disabled="false"></mat-datepicker>
      <mat-error *ngIf="yourForm.controls['frmEndDate'].hasError('required') && yourForm.controls['frmEndDate'].touched">
        End Date is required
      </mat-error>
    </mat-form-field>



    <div *ngIf="yourForm.controls['frmStartDate'].value && yourForm.controls['frmEndDate'].value && !datesAreEqual()">
      <mat-form-field style="width:160px;">
        <mat-label>Duration2</mat-label>
        <mat-select [(value)]="EndDuration" formControlName="EndDuration1">
          <mat-option *ngFor="let option of EndDurations" [value]="option.DurationID">
            {{option.DurationDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="EndDuration === 3">
        <!--custom-->
        <mat-form-field style="width: 120px;">
          <mat-label>End Hour2</mat-label>
          <mat-select formControlName="EndCustomHour1">
            <mat-option *ngFor="let option of dataEndHours" [value]="option.Hour">
              {{option.Hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 130px;">
          <mat-label>End Minute2</mat-label>
          <mat-select formControlName="EndCustomMinute1">
            <mat-option *ngFor="let option of filteredEndMinutes" [value]="option.Minute">
              {{option.Minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <!--Same Day Durations-->
    <div *ngIf="datesAreEqual()">
      <mat-form-field style="width:160px;">
        <mat-label>Duration same</mat-label>
        <mat-select [(value)]="EndDuration" formControlName="EndDuration1">
          <mat-option *ngFor="let option of SameDayDurations" [value]="option.DurationID">
            {{option.DurationDescription}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="EndDuration === 3">
        <!--custom-->
        <mat-form-field style="width: 120px;">
          <mat-label>Start Hour s</mat-label>
          <mat-select formControlName="StartCustomHour1">
            <mat-option *ngFor="let option of dataStartHours" [value]="option.Hour">
              {{option.Hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 130px;">
          <mat-label>Start Minute s</mat-label>
          <mat-select formControlName="StartCustomMinute1">
            <mat-option *ngFor="let option of filteredStartMinutes" [value]="option.Minute">
              {{option.Minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <br />
        <mat-form-field style="width: 120px;">
          <mat-label>End Hour s</mat-label>
          <mat-select formControlName="EndCustomHour1">
            <mat-option *ngFor="let option of dataEndHours" [value]="option.Hour">
              {{option.Hour}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 130px;">
          <mat-label>End Minute s</mat-label>
          <mat-select formControlName="EndCustomMinute1">
            <mat-option *ngFor="let option of filteredEndMinutes" [value]="option.Minute">
              {{option.Minute}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>


    <br />
    <label for="notes">Notes:</label>
    <mat-form-field style="width: 100%;">
      <mat-label>Enter any notes...</mat-label>
      <textarea #Notes matInput formControlName="Notes1" ></textarea>
    </mat-form-field>


  </form>




  <mat-checkbox [(ngModel)]="isAutoApproved" style="margin-bottom:10px;" *ngIf="isManaging">Auto Approve?</mat-checkbox>
  <div style=" margin-top: 20px; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: flex-end; align-items: center; }">
    <div style="padding-right:10px;color:red;">{{submitDisabledReason}}</div>
    <button (click)="onSubmit()" type="submit" [disabled]="yourForm.invalid || isBusy || disableButton" value="Submit">
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; justify-content: space-around;">
        <mat-spinner *ngIf="isBusy" style="max-width:20px;max-height:20px;margin-right:10px;"></mat-spinner>Submit
      </div>
    </button>

  </div>
  <br /><br /><br /><br /><br />


</div>
