<table *ngIf="totalRecords != 0" mat-table [dataSource]="dataMyTime" class="mat-elevation-z8">

  <ng-container matColumnDef="RequestStatusDescription">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> Status </th>
    <td mat-cell *matCellDef="let element"><app-stamp [status]=element.RequestStatusDescription></app-stamp> </td>
  </ng-container>

  <ng-container matColumnDef="AbsenceDescription">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> Absence Type </th>
    <td mat-cell *matCellDef="let element"> {{element.AbsenceDescription}} </td>
  </ng-container>

  <ng-container matColumnDef="StartDateTime">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> Start Date </th>
    <td mat-cell *matCellDef="let element"> {{element.StartDateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="EndDateTime">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> End Date </th>
    <td mat-cell *matCellDef="let element"> {{element.EndDateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="TotalHours">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> Total Hours </th>
    <td mat-cell *matCellDef="let element"> {{element.TotalHours}} </td>
  </ng-container>

  <ng-container matColumnDef="RequestDescription">
    <th mat-header-cell *matHeaderCellDef class="sticky-header"> Notes </th>
    <td mat-cell *matCellDef="let element"> {{element.RequestDescription}} </td>
  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr class="myrow" mat-row (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
<mat-paginator *ngIf="totalRecords > 0" [pageSize]="ItemsPerPage" [pageSizeOptions]="[5,20, 250, 500]" showFirstLastButtons class="table-paginator"></mat-paginator>

<div *ngIf="totalRecords == 0">
  No records for this absence
</div>
