import { Component, OnInit, AfterViewInit, ElementRef, ChangeDetectorRef, Renderer2, Input, ViewChild, ViewContainerRef, EventEmitter, Output } from '@angular/core';
import { DataService } from '../data.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import moment from 'moment';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { DlgApprovalComponent } from '../dlg-approval/dlg-approval.component';
import { TooltipComponent } from '../tooltip/tooltip.component';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-pinboard',
  templateUrl: './pinboard.component.html',
  styleUrl: './pinboard.component.css'
})
export class PinboardComponent implements AfterViewInit {


  @ViewChild('tooltipContainer', { read: ViewContainerRef, static: false }) tooltipContainer!: ViewContainerRef;


  @Input() titleparam!: string;  // Data received from the parent
  @Input() isApproving: boolean = false;  // Data received from the parent

  isAdmin: number = 0;

  rowHeight: number = 50;
  placeHolderHeight: number = 26;


  displayedColumns: any[] = [];
  dataDates: any[] = [];
  rowData: any[] = [];
  dataUserRequests: any[] = [];
  dataUsers: any[] = [];

  dataAllUsersPinboard: any[] = [];

  selectedDateScope: string = 'month';
  DateScopeDisplay: string = "";
  StartDateScope: string = moment().format('DD MMM YYYY'); //holds the datumdate for display 

  constructor(private router: Router, private dataService: DataService, private el: ElementRef, private cdr: ChangeDetectorRef, private renderer: Renderer2, public dialog: MatDialog, private viewContainer: ViewContainerRef) { }


  private resizeObserver!: ResizeObserver;


  onResize() {
    //update just the placeholder positions
    console.log("rezie");
    var prevstart: string = '';
    var prevuser: number = 0;
    var pos: number = 0;

    this.dataUserRequests.forEach((item) => {
      if (prevstart == item.StartDate && prevuser == item.RequestUserID) {
        pos = 2;
      } else {
        pos = 1;
      }
      prevuser = item.RequestUserID;
      prevstart = item.StartDate;
      this.addTodayMarker();
      this.refreshPlaceHolderPositions(item, pos);
    });


  }

  ngOnDestroy() {
    // Disconnect the ResizeObserver
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnInit(): void {


    //listen for event
    this.dataService.refreshPinboardEvent$.subscribe(() => {
      this.RefreshData();
      this.onResize();
    });


    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        //this.onResize();
      }
      this.onResize();
    });


    this.resizeObserver.observe(this.el.nativeElement);

    moment.updateLocale('en', {
      week: {
        dow: 1, // Set Monday as the first day of the week
        doy: 4  // Ensure that the first week of the year is the one with at least 4 days in it
      }
    });


  }


  showUserDashboard(UserIDname: string): void {
    let UserID = UserIDname.replace('UserID', '');//remove 'UserID'
    this.router.navigate(['/dashboard'], {
      queryParams: { UserID: UserID },
      fragment: 'mytime'
    });
  }

  async handlePinBoardGeneration(datescope: string) {
    await this.GeneratePinboard(datescope);
    this.AddPlaceHoldersToPinBoard();
  };


  RefreshData(): void {
    this.handlePinBoardGeneration(this.StartDateScope)
  }


  ngAfterViewInit(): void {
    this.isAdmin = this.dataService.GlobalIsAdmin;

    console.log(this.isAdmin);
    this.handlePinBoardGeneration(this.StartDateScope);
  }


  btnPrevScope() {

    if (this.selectedDateScope === 'week') {
      let givenDate = moment(this.StartDateScope).add(-1, 'weeks');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }


    if (this.selectedDateScope === '2weeks') {
      let givenDate = moment(this.StartDateScope).add(-2, 'weeks');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }



    if (this.selectedDateScope === 'month') {
      let givenDate = moment(this.StartDateScope).add(-1, 'months');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }

  }

  btnNextScope() {

    if (this.selectedDateScope === 'week') {
      let givenDate = moment(this.StartDateScope).add(1, 'weeks');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }

    if (this.selectedDateScope === '2weeks') {
      let givenDate = moment(this.StartDateScope).add(2, 'weeks');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }

    if (this.selectedDateScope === 'month') {
      let givenDate = moment(this.StartDateScope).add(1, 'months');
      this.StartDateScope = moment(givenDate).format('DD MMM YYYY');
      this.handlePinBoardGeneration(this.StartDateScope);
    }
  }



  AddPlaceHoldersToPinBoard(): void {
    //add placeholders
    this.removeAllInstances('.placeholder');
    console.log("Add Placeholders")
    var prevstart: string = '';
    var prevuser: number = 0;
    var pos: number = 0;
    this.addTodayMarker();

    this.dataUserRequests.forEach((item) => {

      if (prevstart == item.StartDate && prevuser == item.RequestUserID) {
        pos = 2;
      } else {
        pos = 1;
      }
      prevuser = item.RequestUserID;
      prevstart = item.StartDate;


      this.addPlaceHolder(item);
      this.refreshPlaceHolderPositions(item, pos);
    });


  }



  GeneratePinboard(StartDateScope: string): Promise<void> {

    return new Promise((resolve, reject) => {


      //this.removeAllInstances('.placeholder');

      var startscopedate: string = '';
      var endscopedate: string = '';


      if (this.selectedDateScope === 'week') {
        startscopedate = moment(StartDateScope).startOf('week').format('DD MMM YYYY');
        endscopedate = moment(StartDateScope).endOf('week').format('DD MMM YYYY');
        this.DateScopeDisplay = startscopedate + ' - ' + endscopedate;
      }


      if (this.selectedDateScope === '2weeks') {
        startscopedate = moment(StartDateScope).startOf('week').format('DD MMM YYYY');
        endscopedate = moment(StartDateScope).endOf('week').add(1, 'week').format('DD MMM YYYY');
        this.DateScopeDisplay = startscopedate + ' - ' + endscopedate;
      }


      if (this.selectedDateScope === 'month') {
        startscopedate = moment(StartDateScope).startOf('month').format('DD MMM YYYY');
        endscopedate = moment(StartDateScope).endOf('month').format('DD MMM YYYY');
        this.DateScopeDisplay = moment(StartDateScope).format('MMMM') + ' ' + moment(StartDateScope).format('YYYY');
      }

      console.log("Getting Pinboard Data");

      this.dataService.dbGeneratePinboard(startscopedate, endscopedate).subscribe({
        next: (response) => {
          this.dataDates = response.dsData;
          this.dataUserRequests = response.dsData1;
          this.dataUsers = response.dsData2;
          this.displayedColumns = ['Name', ...this.dataDates.map(date => date.DateValue)];
        },
        error: (error) => {
          console.error('Error fetching data', error);
        },
        complete: () => {
          console.log("Completed Getting Pinboard Data");

          //this.cdr.detectChanges();
          this.rowData = [];

          this.dataUsers.forEach((item) => {
            const newRow = {
              UserID: 'UserID' + item.UserId,
              DisplayName: item.DisplayName,
              rowheight: item.rowheight,
              UserRow: this.dataDates.map(date => ({
                date: date.DateValue,  // Include the date value
                isWeekend: date.isWeekend,
                isBankHoliday: date.isBankHoliday
              }))
            };
            this.rowData.push(newRow);
          });

          this.cdr.detectChanges();

          //this.addTodayMarker();

          resolve();

        }
      });


    });//end promise


  }



  addTodayMarker(): void {

    //remove all elements
    const elements = document.querySelectorAll('#todaymarker');
    elements.forEach((element) => {
      element.remove();
    });

    const findToday = this.el.nativeElement.querySelector('#a' + moment().format('DDMMMYYYY'));

    if (findToday) {
      //create the marker and position it
      const tabelement = this.el.nativeElement.querySelector('#mytable');
      const newElement = this.renderer.createElement('div');
      this.renderer.setAttribute(newElement, 'id', 'todaymarker');
      this.renderer.appendChild(tabelement, newElement);

      const tab = tabelement.getBoundingClientRect();
      const rectToday = findToday.getBoundingClientRect();
      const element2 = this.el.nativeElement.querySelector('#todaymarker');
      const top = (rectToday.top - tab.top);
      const left = (rectToday.left - tab.left) + (rectToday.width / 2);

      element2.style.top = top + "px";
      element2.style.left = left + "px";
      element2.style.width = "10px";
    }


  }


  removeAllInstances(remElement: string): void {
    console.log("Remove elements " + remElement);
    const components = document.querySelectorAll(remElement); // remove all instances to tooltip
    components.forEach((component: Element) => {
      if (component.parentNode) {
        component.parentNode.removeChild(component); // Remove from its parent
      }
    });
  }


  addPlaceHolder(userRequestData: any): void {

    const tabelement = this.el.nativeElement.querySelector('#mytable');
    //const rowHeight = 50;
    //const placeHolderHeight = 26;

    const newElement = this.renderer.createElement('div');
    // Set some styles or properties for the new element
    this.renderer.setAttribute(newElement, 'id', `RequestID${userRequestData.RequestID}`);
    this.renderer.setAttribute(newElement, 'data-id', `${userRequestData.RequestID}`);
    this.renderer.setAttribute(newElement, 'class', 'placeholder');
    this.renderer.setStyle(newElement, 'color', userRequestData.AbsenceForeColour);
    this.renderer.setProperty(newElement, 'innerText', userRequestData.AbsenceDescription);


    this.renderer.listen(newElement, 'mousemove', (event) => {


      //Add ToolTip
      this.removeAllInstances('app-tooltip');
      const tooltipRef2 = this.viewContainer.createComponent(TooltipComponent);
      var tooltiptext = "";
      tooltipRef2.instance.tooltipTitle = userRequestData.ToolTipTitle;
      tooltipRef2.instance.tooltipDate = userRequestData.ToolTipDescription + ' ('+userRequestData.TotalHours+' hours)';



      if (userRequestData.RequestDescription != '') {
        tooltipRef2.instance.tooltipNotes = 'Notes - '+userRequestData.RequestDescription;
      }
      



      const tooltipElement2 = tooltipRef2.location.nativeElement;
      this.renderer.setStyle(tooltipElement2, 'position', 'fixed');
      this.renderer.setStyle(tooltipElement2, 'z-index', '1000');
      this.renderer.setStyle(tooltipElement2, 'visibility', 'hidden'); // Make it invisible but still render it
      this.renderer.setStyle(tooltipElement2, 'display', 'block'); // Temporarily display it
      const rect = newElement.getBoundingClientRect();
      const mouseX = event.pageX;
      const mouseY = event.pageY;
      const relativeX = mouseX - rect.left - window.pageXOffset;
      const relativeY = mouseY - rect.top - window.pageYOffset;
      tooltipRef2.instance.tooltipReady.subscribe((rectTooltip: DOMRect) => {
        this.renderer.setStyle(tooltipElement2, 'top', (rect.top + relativeY) - (rectTooltip.height) - 20 + 'px');
        this.renderer.setStyle(tooltipElement2, 'left', (rect.left + relativeX) - (rectTooltip.width / 2) + 'px');
      });

      this.renderer.setStyle(tooltipElement2, 'display', 'block');
    });

    this.renderer.listen(newElement, 'mouseleave', () => {
      const components = document.querySelectorAll('app-tooltip'); // remove all instances to tooltip
      components.forEach((component: Element) => {
        if (component.parentNode) {
          component.parentNode.removeChild(component); // Remove from its parent
        }
      });
    });

    this.renderer.setStyle(newElement, 'cursor', 'pointer');


    if (userRequestData.RequestStatusID === 1) {
      this.renderer.setStyle(newElement, 'border', '2px solid ' + userRequestData.AbsenceBackColour);
      this.renderer.setStyle(newElement, 'border-color', userRequestData.AbsenceBackColour);
      this.renderer.setStyle(newElement, 'backgroundColor', 'white');
      this.renderer.setStyle(newElement, 'color', 'black');
    } else {
      this.renderer.setStyle(newElement, 'backgroundColor', userRequestData.AbsenceBackColour);
    }


    // Append the new element to the parent container
    this.renderer.appendChild(tabelement, newElement);

    this.renderer.listen(newElement, 'click', () => {
      this.removeAllInstances('app-tooltip');
      var RequestID: number = parseInt(newElement.getAttribute('data-id'));


      this.dataService.OpenSidePanel({ requestID: RequestID, isApproving: this.isApproving });



    });


    // this.refreshPlaceHolderPositions(userRequestData)

  }






  async refreshPlaceHolderPositions(userRequestData: any, pos: number) {
    return new Promise((resolve, reject) => {



      var tabelement = this.el.nativeElement.querySelector('#mytable');


      //position the request
      var findstartdate = this.el.nativeElement.querySelector('#a' + userRequestData.StartDate.replace(/\s+/g, ''));
      var findenddate = this.el.nativeElement.querySelector('#a' + userRequestData.EndDate.replace(/\s+/g, ''));
      var finduserrow = this.el.nativeElement.querySelector('#UserID' + userRequestData.RequestUserID);

      if (findstartdate) {
        var tab = tabelement.getBoundingClientRect();
        var rectStart = findstartdate.getBoundingClientRect();
        var rectEnd = findenddate.getBoundingClientRect();
        var rectRow = finduserrow.getBoundingClientRect();


        var top = (rectRow.top - tab.top) + ((this.rowHeight / 2) - (this.placeHolderHeight / 2)) + (50 * pos) - 50;
        var left = rectStart.left - tab.left;

        var width = (rectEnd.left - tab.left) - (rectStart.left - tab.left) + rectEnd.width;
        var element2 = this.el.nativeElement.querySelector(`#RequestID${userRequestData.RequestID}`);
        element2.style.top = top + "px";

        element2.style.left = left + 5 + "px";
        element2.style.width = width - 10 + "px";

        if (userRequestData.RequestStartDuration === 4) {
          element2.style.left = left + 5 + "px";
          element2.style.width = width - 10 + "px";
        } else if (userRequestData.RequestStartDuration === 2) {//afternoon
          var half = rectStart.width / 2;
          element2.style.left = left + 5 + half + "px";
          element2.style.width = width - 10 - half + "px";
        } else if (userRequestData.RequestStartDuration === 1) {//morning
          var half = rectStart.width / 2;
          element2.style.left = left + 5 + "px";
          element2.style.width = width - 10 - half + "px";
        } else if (userRequestData.RequestStartDuration === 3) {//custom


          var half = rectStart.width / 2;
          element2.style.left = left + (half / 2) + 5 + "px";
          element2.style.width = half / 2 + "px";
        }
      }



    });


  };

  onSelectedDateScope(event: any) {
    this.handlePinBoardGeneration(this.StartDateScope);
  };



}
