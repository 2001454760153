import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PasswordMatchValidator } from '../customvalidators/password-match.validator'; // Import the custom validator
import { DataService } from '../data.service';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-requestchange',
  templateUrl: './requestchange.component.html',
  styleUrl: './requestchange.component.css'
})
export class RequestchangeComponent {

  constructor(private fb: FormBuilder, private router: Router, private dataService: DataService, private route: ActivatedRoute) { }


  resetForm!: FormGroup;
  urlToken: string = "";
  urlEmail: string = "";

  isVerified: boolean = false;



  ngOnInit(): void {


    this.route.queryParamMap.subscribe(params => {
      this.urlToken = params.get('token') ?? '';
      this.urlEmail = params.get('email') ?? '';
      console.log('Token:', this.urlToken);  // Logs token from query string
      console.log('Email:', this.urlEmail);  // Logs email from query string

      this.dataService.dbVerifyForgotToken(this.urlToken, this.urlEmail).subscribe(
        data => {
          if (data.dsData[0].access == 'Allowed') {
            this.isVerified = true
          }
        },
        error => {
          console.error('Error fetching options:', error);
        }
      );



    });


    this.resetForm = this.fb.group({
      password: ['', [Validators.required,Validators.minLength(8)]],
      confirmpassword: ['', [Validators.required, Validators.minLength(8)]]
    },
      { validators: PasswordMatchValidator() }
    );
  }

  resetPassword(): void {
    if (this.resetForm.valid) {
      console.log("ok")
    
      //save, redirect to login and popup message

      const password = this.resetForm.get('password')?.value;


      this.dataService.dbResetPassword(this.urlToken, this.urlEmail, password).pipe(
        finalize(() => {
          this.router.navigate(['/login']);
        })
      ).subscribe(
        data => {
        },
        error => {
          console.error('Error fetching options:', error);
        }
      );


    }
  }

}
