<div class="login-container">
  <img src="assets/images/alsimlogo.png" class="login-logo">
  <mat-card class="login-card" *ngIf="isVerified">
    <mat-card-header class="login-header">
      <mat-card-title class="title">Reset Password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="resetForm" (ngSubmit)="resetPassword()">


        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Password</mat-label>
          <input matInput formControlName="password" placeholder="Password" type="password" #passwordInput required />
          <mat-error *ngIf="resetForm.get('password')?.hasError('minlength')">Password must be at least 8 characters</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="full-width">
          <mat-label>Confirm Password</mat-label>
          <input matInput formControlName="confirmpassword" placeholder="Confirm Password" type="password" #confirmpasswordInput required />
          <mat-error *ngIf="resetForm.get('confirmpassword')?.hasError('minlength')">Confirm Password must be at least 8 characters</mat-error>
          <mat-error *ngIf="resetForm.hasError('passwordMismatch')">Passwords must match</mat-error>
        </mat-form-field>


        <div class="login-actions">
          <button mat-raised-button color="primary" [disabled]="resetForm.invalid">Reset Password</button>
        </div>

      </form>
      <a class="forgot-password" [routerLink]="['/login']">Back to Login</a>
    </mat-card-content>
  </mat-card>


  <mat-card class="login-card" *ngIf="!isVerified">
    <div>Token Expired</div>
    <a class="forgot-password" [routerLink]="['/login']">Back to Login</a>
  </mat-card>

</div>
