import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { DlgUserComponent } from '../dlg-user/dlg-user.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-usermaintenance',
  templateUrl: './usermaintenance.component.html',
  styleUrl: './usermaintenance.component.css'
})
export class UsermaintenanceComponent implements OnInit, AfterViewInit {


  constructor(private dataService: DataService, public dialog: MatDialog) { }


  @ViewChild(MatPaginator) paginator!: MatPaginator;


  dataGetUsers = new MatTableDataSource<any>();
  displayedColumns: string[] = ['DisplayName', 'Email', 'Department', 'Balance2024', 'Balance2025', 'IsAdmin', 'IsApprover'];


  ngOnInit(): void {
    this.refreshData();
  }

  refreshData(): void {
    this.dataService.dbAdminGetUsers().subscribe((response) => {
      this.dataGetUsers.data = response.dsData;
    });




  }


  ngAfterViewInit() {
    this.dataGetUsers.paginator = this.paginator;
  }

  onRowClick(row: any) {


    const dialogRef = this.dialog.open(DlgUserComponent, {
      width: '60%',
      maxWidth: '90vw',
      minWidth: '300px',
      maxHeight: '600px',
      disableClose: true, 
      data: { userid: row.UserId }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshData();
      console.log('Dialog closed:', result);
    });
  }




}
