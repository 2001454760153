import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-layoutv1',
  templateUrl: './layoutv1.component.html',
  styleUrl: './layoutv1.component.css'
})
export class Layoutv1Component implements OnInit {


  menuOption: string = "mytime";

  constructor(private route: ActivatedRoute, private router: Router) { }


  ngOnInit() {
    // Subscribe to the hash fragment (URL hash changes)
    this.route.fragment.subscribe(fragment => {
      console.log('Current Hash layot:', fragment);
      this.loadComponentBasedOnHash(fragment);
    });
  }



  loadComponentBasedOnHash(fragment: string | null) {
    this.menuOption = fragment || "";
  }


}
