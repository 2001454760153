import { Component, AfterViewInit, ChangeDetectorRef, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../data.service';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.css'
})
export class TooltipComponent implements AfterViewInit {
  @Input() tooltipDate: string = '';
  @Input() tooltipTitle: string = '';
  @Input() tooltipNotes: string = '';
  @Output() tooltipReady = new EventEmitter<DOMRect>();
  @ViewChild('tooltipContainer', { static: true }) tooltipContainer!: ElementRef;


  isAdmin: boolean = false;


  constructor(private cdr: ChangeDetectorRef, private dataService: DataService) { }


  ngAfterViewInit() {


    this.isAdmin = Number(this.dataService.GlobalIsAdmin) !== 0;

    this.cdr.detectChanges(); // Ensure view is updated
    const rect = this.tooltipContainer.nativeElement.getBoundingClientRect();
    this.tooltipReady.emit(rect); // Emit the tooltip's position and size



  }



}
