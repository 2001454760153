
<header>
  <h1>Time Planner</h1>
</header>

  <div class="container">
    <nav>
      <ul>
        <li><a href="#mytime">My Time Off</a></li>
        <li><a href="#requesttime">Request Time Off</a></li>
        <li><a href="#peoplestime">Peoples Time Off</a></li>
      </ul>
    </nav>

    <main>
      <app-mytime *ngIf="menuOption == 'mytime'"></app-mytime>
      <app-requesttime *ngIf="menuOption == 'requesttime'"></app-requesttime>
      <app-peoplestime *ngIf="menuOption == 'peoplestime'"></app-peoplestime>
    </main>
  </div>
