import { Component, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-peoplestime',
  templateUrl: './peoplestime.component.html',
  styleUrl: './peoplestime.component.css'
})
export class PeoplestimeComponent {


  //@Output() openSidenav = new EventEmitter<number>();
  //// Listen for the event emitted from Pinboard
  //handleOpenSidenav(requestID: number): void {
  //  this.openSidenav.emit(requestID);
  //}

  @Output() openSidenav = new EventEmitter<{ requestID: number, isApproving: boolean }>();
  // Listen for the event emitted from Pinboard
  handleOpenSidenav(data: { requestID: number; isApproving: boolean }): void {
    this.openSidenav.emit(data);
  }




  //@Output() openSidenav = new EventEmitter<number>();
  //// Listen for the event emitted from Pinboard
  //handleOpenSidenav(RequestID: number): void {
  //  this.openSidenav.emit(RequestID);
  //}



} 
