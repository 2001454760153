import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { PinboardComponent } from '../pinboard/pinboard.component';

@Component({
  selector: 'app-pendingapprovals',
  templateUrl: './pendingapprovals.component.html',
  styleUrl: './pendingapprovals.component.css'
})



export class PendingapprovalsComponent  {
  @ViewChild(PinboardComponent) pinboardComponent!: PinboardComponent;

  onTabChange(event: MatTabChangeEvent): void {
  //  //make sure all tooltips are removed when switching tabs
     this.pinboardComponent.removeAllInstances('app-tooltip');


  }




}
