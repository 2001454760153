import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';  // Import your AuthService
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../data.service';
import { GlobalService } from '../global.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.css'
})
export class MenuComponent {

  constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService, private dataService: DataService, private globalService: GlobalService) { }


  DisplayName: string = "";
  IsAdmin: number = 0;
  IsApprover: number = 0;
  dataMyTime: any;
  menuOption: string = "mytime";

  ngOnInit() {
    // Subscribe to the hash fragment (URL hash changes)

    this.DisplayName = this.dataService.GlobalName;
    this.IsAdmin = this.dataService.GlobalIsAdmin;
    this.IsApprover = this.dataService.GlobalIsApprover;
    this.globalService.GetSystemData();//get system global defaults


    //this.route.fragment.subscribe(fragment => {
   //   this.loadComponentBasedOnHash(fragment);
    //});



  }


  loadComponentBasedOnHash(fragment: string | null) {
    this.menuOption = fragment || "";
  }


  Logout() {
    this.authService.logout().subscribe({
      next: () => this.router.navigate(['/login'])
    });
  }




}
