import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '../data.service';
import { environment } from '../../environments/environment'; // Correct import path

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrl: './forgotpassword.component.css'
})
export class ForgotpasswordComponent {



  @ViewChild('usernameInput') usernameInput: ElementRef<HTMLInputElement> | undefined;


  constructor(private fb: FormBuilder, private router: Router, private cdr: ChangeDetectorRef, private dataService: DataService) { }


  showConfirm: boolean = false;
  dev: string = "";

  loginForm!: FormGroup;

  ngAfterViewInit(): void {
    // Autofocus the username input when the view is initialized
    if (this.usernameInput) {
      this.usernameInput.nativeElement.focus();
    }
    this.cdr.detectChanges();

  }


  ngOnInit(): void {
    this.loginForm = this.fb.group({
      emailaddress: ['', [Validators.required]]
    });


    if (!environment.production) {
      this.dev = 'dev';
    }



  }

  onLogin(): void {
    if (this.loginForm.valid) {

      this.dataService.dbForgotPassword(this.loginForm.get('emailaddress')?.value).subscribe((response) => {});

      //show confirm
      this.showConfirm = true;

     // this.router.navigate(['/login']); // Redirect if not authenticated


    }
  }


}
