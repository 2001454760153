import { Component } from '@angular/core';

@Component({
  selector: 'app-requestsent',
  templateUrl: './requestsent.component.html',
  styleUrl: './requestsent.component.css'
})
export class RequestsentComponent {

}
