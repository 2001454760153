
<div class="stamp clsApproved" *ngIf="status === 'Approved'">
  <div class="bullet"></div>Approved
</div>

<div class="stamp clsPending" *ngIf="status === 'Pending'">
  <div class="bullet"></div>Pending
</div>

<div class="stamp clsCancelled" *ngIf="status === 'Cancelled'">
  <div class="bullet"></div>Cancelled
</div>

<div class="stamp clsDeclined" *ngIf="status === 'Declined'">
  <div class="bullet"></div>Declined
</div>

