import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-mytime',
  templateUrl: './mytime.component.html',
  styleUrl: './mytime.component.css'
})
export class MytimeComponent {


  UserID: number =0;
  UserName: string = "";

  constructor(private route: ActivatedRoute, private dataService: DataService) {
  }


  ngOnInit(): void {


    this.route.queryParams.subscribe(params => {
      if (params['UserID']) {
        this.UserID = params['UserID'];    // Access the userId query parameter
      }
    });

    if (this.dataService.GlobalIsAdmin && this.UserID != 0) {
      this.dataService.dbAdminGetUsername(this.UserID).subscribe((response) => {
        this.UserName = ' (' + response.dsData[0].DisplayName + ')';
      });
    } else {
      this.UserName = "";
    }


  }





}
