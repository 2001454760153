import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { environment } from '../environments/environment'; // Correct import path

@Injectable({ 
  providedIn: 'root'
})



export class AuthGuard implements CanActivate {
  constructor(private http: HttpClient, private router: Router, private dataService: DataService) { }


  AppToken: any;

  canActivate(): Observable<boolean> {


    //return this.http.get<any>(`${this.dataService.apidomain}/api/auth/check-auth`, { withCredentials: true }).pipe(
    return this.http.get<any>(`${environment.apiUrl}/api/auth/check-auth`, { withCredentials: true }).pipe(
      map(response => {

        console.log('User is authenticated - authguard');

        this.AppToken = response.token;

        //this.dataService.setHeader(response.token);

        const decoded = this.decodeToken(response.token);
        if (decoded) {
          //console.log('User Role:', decoded.role);
          //console.log('DispName:', decoded.DisplayName);
          //console.log('admin:', decoded.IsAdmin);
        }
        this.dataService.GlobalUserID = decoded.UserID;
        this.dataService.GlobalName = decoded.DisplayName;
        this.dataService.GlobalIsAdmin = decoded.IsAdmin;
        this.dataService.GlobalIsApprover = decoded.IsApprover;
        return true; // Allow access if authenticated
      }),
      catchError(() => {
        console.log('User is not authenticated or session expired!');
        this.router.navigate(['/login']); // Redirect if not authenticated
        return of(false);
      })
    );
    

  }

  getAuthToken(): string{
    return this.AppToken;
  }

  decodeToken(token: string): any | null {
    try {
      // Split the token by dots to separate header, payload, and signature
      const payloadBase64 = token.split('.')[1];
      if (payloadBase64) {
        // Decode the payload from Base64
        const decodedPayload = atob(payloadBase64);
        // Parse the JSON payload

       // console.log(decodedPayload);
        return JSON.parse(decodedPayload);
      }
      return null;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }



}
