import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../data.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-dlg-user',
  templateUrl: './dlg-user.component.html',
  styleUrl: './dlg-user.component.css'
})
export class DlgUserComponent implements OnInit{



  constructor(public dialogRef: MatDialog, private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) { }

  dataUser: any;
  dataUserAbsences: any[] = [];
  dataApprovers: any[] = [];
  dataGetUserViewScope: any[] = [];
  dataGetDepartments: any[] = [];


  email: string ="";
  DisplayName: string = "";
  IsAdmin: boolean = false;
  IsApprover: boolean = false;
  DeptID: number = 0;
  UserID: number = 0;

  MONStart: string = "";
  MONEnd: string = "";
  MONLunch: string = "";
  TUEStart: string = "";
  TUEEnd: string = "";
  TUELunch: string = "";
  WEDStart: string = "";
  WEDEnd: string = "";
  WEDLunch: string = "";
  THUStart: string = "";
  THUEnd: string = "";
  THULunch: string = "";
  FRIStart: string = "";
  FRIEnd: string = "";
  FRILunch: string = "";
  SATStart: string = "";
  SATEnd: string = "";
  SATLunch: string = "";
  SUNStart: string = "";
  SUNEnd: string = "";
  SUNLunch: string = "";

 


  displayedColumns: string[] = ['AbsenceDescription', 'firstapprover', 'secondapprover', 'isNotifier'];

  displayedDepartmentColumns: string[] = ['DeptDescription'];



  ngOnInit(): void {



    this.dataService.dbGetDepartments().subscribe((response) => {
      this.dataGetDepartments = response.dsData;
    });


    /*
    this.dataService.dbAdminGetUserViewScope(this.data.userid).subscribe((response) => {
      console.log(this.data.userid);
      this.dataGetUserViewScope = response.dsData.map((department: { AllowedView: number; }) => ({
        ...department,
        AllowedView: department.AllowedView === 1 // Convert to boolean
      }));
    });
    */
   

    this.dataService.dbAdminGetUserViewScope(this.data.userid).subscribe((response) => {

      this.dataGetUserViewScope = response.dsData;
    });


    this.dataService.dbAdminGetUser(this.data.userid).subscribe((response) => {

      this.dataUser = response.dsData[0];//only one record hence [0] General
   
       this.dataUserAbsences = response.dsData2;//Allowed Absences
 

      //populate general user info
      this.UserID = this.dataUser.UserId
      this.email = this.dataUser.Email;
      this.DisplayName = this.dataUser.DisplayName;
      this.IsAdmin = this.dataUser.IsAdmin === 1;
      this.IsApprover = this.dataUser.IsApprover === 1;
      this.DeptID = this.dataUser.DeptID;


      //populate hours of work
      this.MONStart = response.dsData1[0].MONStartTime
      this.TUEStart = response.dsData1[0].TUEStartTime
      this.WEDStart = response.dsData1[0].WEDStartTime
      this.THUStart = response.dsData1[0].THUStartTime
      this.FRIStart = response.dsData1[0].FRIStartTime
      this.SATStart = response.dsData1[0].SATStartTime
      this.SUNStart = response.dsData1[0].SUNStartTime
      this.MONEnd = response.dsData1[0].MONEndTime
      this.TUEEnd = response.dsData1[0].TUEEndTime
      this.WEDEnd = response.dsData1[0].WEDEndTime
      this.THUEnd = response.dsData1[0].THUEndTime
      this.FRIEnd = response.dsData1[0].FRIEndTime
      this.SATEnd = response.dsData1[0].SATEndTime
      this.SUNEnd = response.dsData1[0].SUNEndTime
      this.MONLunch = response.dsData1[0].MONLunch
      this.TUELunch = response.dsData1[0].TUELunch
      this.WEDLunch = response.dsData1[0].WEDLunch
      this.THULunch = response.dsData1[0].THULunch
      this.FRILunch = response.dsData1[0].FRILunch
      this.SATLunch = response.dsData1[0].SATLunch
      this.SUNLunch = response.dsData1[0].SUNLunch


    });



    this.dataService.dbGetApprovers().subscribe((response) => {
      this.dataApprovers = response.dsData;
    });


  }



  saveData(): void {

 
    var arrayCheckedAbsenceIDs = this.dataUserAbsences.filter(absencetype => absencetype.UserIsAllowed === true || absencetype.UserIsAllowed === 1).map(absencetype => absencetype.AbsenceID).join(',');
    var arrayCheckedApprover1IDs = this.dataUserAbsences.filter(absencetype => absencetype.UserIsAllowed === true || absencetype.UserIsAllowed === 1).map(absencetype => absencetype.Approver1UserID).join(',');
    var arrayCheckedApprover2IDs = this.dataUserAbsences.filter(absencetype => absencetype.UserIsAllowed === true || absencetype.UserIsAllowed === 1).map(absencetype => absencetype.Approver2UserID).join(',');
    var arrayCheckedNotifieds = this.dataUserAbsences.filter(absencetype => absencetype.UserIsAllowed === true || absencetype.UserIsAllowed === 1).map(absencetype => absencetype.isNotifier ? 1 : 0).join(',');


    var arrayDepartmentIDs = this.dataGetUserViewScope.filter(Department => Department.AllowedView === true || Department.AllowedView === 1).map(Department => Department.DeptID).join(',');



    const dataUserGeneral = {
      UserID: this.UserID, DisplayName: this.DisplayName, email: this.email, IsAdmin: this.IsAdmin ? 1 : 0, IsApprover: this.IsApprover ? 1 : 0,DeptID: this.DeptID,
      MONStartTime: this.MONStart, MONEndTime: this.MONEnd,MONLunch: this.MONLunch,
      TUEStartTime: this.TUEStart, TUEEndTime: this.TUEEnd, TUELunch: this.TUELunch,
      WEDStartTime: this.WEDStart, WEDEndTime: this.WEDEnd, WEDLunch: this.WEDLunch,
      THUStartTime: this.THUStart, THUEndTime: this.THUEnd, THULunch: this.THULunch,
      FRIStartTime: this.FRIStart, FRIEndTime: this.FRIEnd, FRILunch: this.FRILunch,
      SATStartTime: this.SATStart, SATEndTime: this.SATEnd, SATLunch: this.SATLunch,
      SUNStartTime: this.SUNStart, SUNEndTime: this.SUNEnd, SUNLunch: this.SUNLunch,


      


      AbsenceTypesIDArray: arrayCheckedAbsenceIDs,
      AbsenceApprover1Array: arrayCheckedApprover1IDs,
      AbsenceApprover2Array: arrayCheckedApprover2IDs,
      AbsenceApproverCanBeNotified: arrayCheckedNotifieds,
      DepartmentsViewable: arrayDepartmentIDs
    };



    console.log(dataUserGeneral);
 
    this.dataService.dbAdminSaveUser(dataUserGeneral).subscribe(
      response => {
        console.log('saved!');
      },
      error => {
        console.error('Error:', error);
      }
    );

    this.dialogRef.closeAll();
  }








}
