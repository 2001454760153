import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataService } from '../data.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-sidepanelcontent',
  templateUrl: './sidepanelcontent.component.html',
  styleUrl: './sidepanelcontent.component.css'
})
export class SidepanelcontentComponent implements OnInit {

  constructor(private dataService: DataService, private cdr: ChangeDetectorRef) { }


  isBusy: boolean = false;
  hasApproved: boolean = true;

  isApproving: boolean = false;
  dataRequest: any;
  AbsenceTitle: string = "test";
  AbsenceBackColour: string = "";
  AbsenceForeColour: string = "";
  hasDetail: Boolean = false;

  DisplayName: string = "";
  StartDateTime: string = "";
  EndDateTime: string = "";
  TotalHours: string = "";
  RequestDescription: string = "";
  RequestResponseNotes: string = "";
  RequestStatusID: number = 0;
  RequestStatusDescription: string = "";
  RequestID: number = 0;
  PendingApproverID: number = 0;
  PendingApproverDisplayName: string = "";
  Approver1DisplayName: string = "";
  Approver2DisplayName: string = "";
  App1Pending: string = "";
  App2Pending: string = "";

  DateRange: string = "";
  isApproverForUser: boolean = false;
  isAdmin: boolean = false;
  isViewingUser: boolean = false;
  RequestUserID: number = 0;
  AbsenceID: number = 0;
  isAllowedForExternalWork: boolean = false;


  TableOfDetails!: SafeHtml;


  ngOnInit(): void {



  }




  saveData(): void {

    const data = {
      RequestID: this.RequestID, RequestStatusID: this.RequestStatusID, RequestResponseNotes: this.RequestResponseNotes
    };

    this.isBusy = true;

    this.dataService.dbAdminSaveRequestResponse(data).subscribe(
      response => {
        this.isBusy = false;
        this.dataService.RefreshPinboard();
        this.dataService.RefreshPendingApprovalsList();
        this.dataService.CloseSidePanel();

      },
      error => {
        console.error('Error:', error);
      }
    );



  }


  selectedButton: number = 0;

  btnApprove(): void {
    this.RequestStatusID = 2;
    this.saveData();
  }

  btnDecline(): void {
    this.RequestStatusID = 3;
  }

  btnCancelAbsence(): void {
    this.RequestStatusID = 4;
    this.saveData();
  }





  public loadRequest(data: any): void {



    //clear all fields
    this.App1Pending = "";
    this.App2Pending = "";
    this.RequestResponseNotes = "";

    this.RequestStatusID = 0;
    this.isApproving = data.isApproving;
    this.RequestID = data.requestID;

    

    this.dataService.dbAdminGetRequest(data.requestID).subscribe((response) => {

      this.dataRequest = response.dsData[0];//only one record hence [0]



      this.hasApproved = true;

      if (this.dataRequest.ApprovingRequired == 1) {
        this.hasApproved = false;
      } else {
        this.hasApproved = true;
      };


      this.DisplayName = this.dataRequest.DisplayName;
      this.StartDateTime = this.dataRequest.StartDateTime;
      this.EndDateTime = this.dataRequest.EndDateTime;
      this.RequestDescription = this.dataRequest.RequestDescription;
      this.AbsenceBackColour = this.dataRequest.AbsenceBackColour;
      this.AbsenceForeColour = this.dataRequest.AbsenceForeColour;
      this.AbsenceTitle = this.dataRequest.AbsenceDescription;
      this.RequestDescription = this.dataRequest.RequestDescription;

      this.PendingApproverID = Number(this.dataRequest.PendingApproverID);
      this.PendingApproverDisplayName = this.dataRequest.PendingApproverDisplayName;
      this.Approver1DisplayName = this.dataRequest.Approver1DisplayName;
      this.Approver2DisplayName = this.dataRequest.Approver2DisplayName;
      this.AbsenceID = this.dataRequest.AbsenceID;
      this.RequestUserID = this.dataRequest.RequestUserID;



     


      this.hasDetail = false;
      this.TableOfDetails = "<table><tbody>"
   
      if (this.dataRequest.ManagedUser != '' && (this.isAdmin || this.isApproverForUser || this.isApproving )) {
        this.TableOfDetails = this.TableOfDetails + "<tr><td>Submitted by -</td><td>" + this.dataRequest.ManagedUser + "</td></tr>"
        this.hasDetail = true;
      }

      if (this.Approver1DisplayName != '' && this.Approver2DisplayName != '') {
        this.TableOfDetails = this.TableOfDetails + "<tr><td>1st Approver -</td><td>" + this.Approver1DisplayName + ' ' + this.dataRequest.Approver1Status + "<br>" + this.dataRequest.Approver1DateStamp +"</td></tr>"
        this.TableOfDetails = this.TableOfDetails + "<tr><td>2nd Approver -</td><td>" + this.Approver2DisplayName + ' ' + this.dataRequest.Approver2Status + "<br>" + this.dataRequest.Approver2DateStamp +"</td></tr>"
        this.hasDetail = true;
      }

      if (this.Approver1DisplayName != '' && this.Approver2DisplayName == '') {
        this.TableOfDetails = this.TableOfDetails + "<tr><td></td><td>Approver -</td><td>" + this.Approver1DisplayName + ' ' + this.dataRequest.Approver1Status + "</td></tr>"
        this.hasDetail = true;
      }

      this.TableOfDetails = this.TableOfDetails + "</tbody></table>"


      this.cdr.detectChanges();




      if (this.RequestUserID == this.dataService.GlobalUserID) {
        this.isViewingUser = true
      } else {
        this.isViewingUser = false
      }

      if (this.dataService.GlobalIsAdmin == 0) {
        console.log('admin false');
        this.isAdmin = false;
      } else {
        console.log('admin true');
        this.isAdmin = true;
      }
    
      this.isAllowedForExternalWork = false;
      //hardcoded...
      if (this.AbsenceID == 8) {
        if (this.dataService.GlobalUserID == 80 || this.dataService.GlobalUserID == 12 || this.dataService.GlobalUserID == 81 || this.dataService.GlobalUserID == 77) {
          this.isAllowedForExternalWork = true;
        }
      }





      //show to apporve panel if the approving user pending and allowed
      if (this.dataService.GlobalUserID == this.dataRequest.PendingApproverID) {
        this.isApproverForUser = true;
      } else {
        this.isApproverForUser = false;
      }



      const sdate = moment(this.dataRequest.StartDateTime, 'DD MMM YYYY HH:mm:ss');
      const edate = moment(this.dataRequest.EndDateTime, 'DD MMM YYYY HH:mm:ss');
      this.DateRange = sdate.format('D MMM YYYY (h:mma)') + ' - ' + edate.format('D MMM YYYY (h:mma)');
      this.TotalHours = this.dataRequest.TotalHours + ' hours';

      this.RequestStatusDescription = this.dataRequest.RequestStatusDescription;

      this.cdr.detectChanges();
   
    });




  }



}
