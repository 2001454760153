<table mat-table [dataSource]="dataGetPendingApprovals" class="mat-elevation-z8">

  <ng-container matColumnDef="DisplayName">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> {{element.DisplayName}} </td>
  </ng-container>

  <ng-container matColumnDef="AbsenceDescription">
    <th mat-header-cell *matHeaderCellDef> Absence Type </th>
    <td mat-cell *matCellDef="let element"> {{element.AbsenceDescription}} </td>
  </ng-container>

  <ng-container matColumnDef="StartDateTime">
    <th mat-header-cell *matHeaderCellDef> Start Date </th>
    <td mat-cell *matCellDef="let element"> {{element.StartDateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="EndDateTime">
    <th mat-header-cell *matHeaderCellDef> End Date </th>
    <td mat-cell *matCellDef="let element"> {{element.EndDateTime}} </td>
  </ng-container>

  <ng-container matColumnDef="TotalHours">
    <th mat-header-cell *matHeaderCellDef> Total Hours </th>
    <td mat-cell *matCellDef="let element"> {{element.TotalHours}} </td>
  </ng-container>

  <ng-container matColumnDef="RequestDescription">
    <th mat-header-cell *matHeaderCellDef> Notes </th>
    <td mat-cell *matCellDef="let element"> {{element.RequestDescription}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr class="myrow" mat-row (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator [pageSize]="250" [pageSizeOptions]="[20, 250, 500]" showFirstLastButtons></mat-paginator>



