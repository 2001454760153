<h1>Pending Approvals</h1>

<mat-tab-group id="pendingapprovalsgroup" style="display:flex;overflow:hidden;" (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Pending Approval List">
    <app-pendingapprovallist class="layout"></app-pendingapprovallist>
  </mat-tab>
  <mat-tab label="Calendar View">
    <app-pinboard class="layout" [isApproving]=true></app-pinboard>
  </mat-tab>
</mat-tab-group>



