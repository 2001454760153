import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';  
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'; // Correct import path

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit, AfterViewInit {

  @ViewChild('usernameInput') usernameInput: ElementRef<HTMLInputElement> | undefined;

  private apiUrl = environment.apiUrl;

  loginForm!: FormGroup;
  error: string = "";
  dev: string = "";



  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    // Autofocus the username input when the view is initialized
    if (this.usernameInput) {
      this.usernameInput.nativeElement.focus();
    }
    this.cdr.detectChanges();

  }


  ngOnInit(): void {


    if (!environment.production) {
      this.dev = 'dev';
    }
  


    console.log(this.apiUrl);

    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      rememberMe: [false]  // For the 'Remember me' checkbox
    });
  }

  onLogin(): void {


    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      //console.log('Logging in with:', username, password);


      this.authService.login(username, password).subscribe(
        response => {
          this.router.navigate(['/dashboard'], { fragment: 'mytime' });
        },
        error => {
          console.error('Login failed', error);
          this.error = 'Login Failed';


          setTimeout(() => {
            this.error = "";
          }, 3000); // 3 seconds


        }
      );



    }



  }


}
