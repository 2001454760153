import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  title = 'Core';


  constructor(private location: Location) { }

  ngOnInit() {

    
    if (location.protocol !== 'https:' && location.hostname !== 'localhost') {
      window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
    }
  }


}
