

<div style="padding:20px;" class="movable-dialog" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  

  <div mat-dialog-content class="dialog-content">


    <mat-tab-group style="max-height:500px;" class="layout">


      <mat-tab label="General">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput [(ngModel)]="DisplayName">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="email">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Department</mat-label>
          <mat-select [(ngModel)]="DeptID">
            <mat-option *ngFor="let option of dataGetDepartments" [value]="option.DeptID">
              {{option.DeptDescription}}
            </mat-option>
          </mat-select>
        </mat-form-field>


        <mat-checkbox class="example-margin" [(ngModel)]="IsAdmin">Is Admin?</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="IsApprover">Is Approver?</mat-checkbox>
      </mat-tab>




        <mat-tab label="View Scope">
          <table mat-table [dataSource]="dataGetUserViewScope">
            <ng-container matColumnDef="DeptDescription">
              <th mat-header-cell *matHeaderCellDef> Viewable Department </th>
              <td mat-cell *matCellDef="let Department">
                <mat-checkbox [id]="'DeptID' + Department.DeptID"
                              [(ngModel)]="Department.AllowedView"
                              [checked]="Department.AllowedView === 1">
                    {{ Department.DeptDescription }}
                  </mat-checkbox>
              </td>
            </ng-container>

            <!-- Header and Row for the table -->
            <tr mat-header-row *matHeaderRowDef="displayedDepartmentColumns"></tr>
            <tr style="height: 34px;" mat-row *matRowDef="let row; columns: displayedDepartmentColumns;"></tr>
          </table>
        </mat-tab>



        <mat-tab label="Work Hours">


          <table>
            <thead>
              <tr>
                <th>Day</th>
                <th>Time Start</th>
                <th>Time End</th>
                <th>Lunch</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>MON</td>
                <td><input [(ngModel)]="MONStart"></td>
                <td><input [(ngModel)]="MONEnd"></td>
                <td><input [(ngModel)]="MONLunch"></td>
              </tr>
              <tr>
                <td>TUE</td>
                <td><input [(ngModel)]="TUEStart"></td>
                <td><input [(ngModel)]="TUEEnd"></td>
                <td><input [(ngModel)]="TUELunch"></td>
              </tr>
              <tr>
                <td>WED</td>
                <td><input [(ngModel)]="WEDStart"></td>
                <td><input [(ngModel)]="WEDEnd"></td>
                <td><input [(ngModel)]="WEDLunch"></td>
              </tr>
              <tr>
                <td>THU</td>
                <td><input [(ngModel)]="THUStart"></td>
                <td><input [(ngModel)]="THUEnd"></td>
                <td><input [(ngModel)]="THULunch"></td>
              </tr>
              <tr>
                <td>FRI</td>
                <td><input [(ngModel)]="FRIStart"></td>
                <td><input [(ngModel)]="FRIEnd"></td>
                <td><input [(ngModel)]="FRILunch"></td>
              </tr>
              <tr>
                <td>SAT</td>
                <td><input [(ngModel)]="SATStart"></td>
                <td><input [(ngModel)]="SATEnd"></td>
                <td><input [(ngModel)]="SATLunch"></td>
              </tr>
              <tr>
                <td>SUN</td>
                <td><input [(ngModel)]="SUNStart"></td>
                <td><input [(ngModel)]="SUNEnd"></td>
                <td><input [(ngModel)]="SUNLunch"></td>
              </tr>
            </tbody>
          </table>

        </mat-tab>

        <mat-tab label="Allowed Absences">

          <table mat-table [dataSource]="dataUserAbsences">



            <!-- Column for Absence Description -->
            <ng-container matColumnDef="AbsenceDescription">
              <th mat-header-cell *matHeaderCellDef> Absence Type </th>
              <td mat-cell *matCellDef="let absencetype">
                <mat-checkbox [id]="'absenceid' + absencetype.AbsenceID"
                              [(ngModel)]="absencetype.UserIsAllowed"
                              [checked]="absencetype.UserIsAllowed === 1">
                  {{ absencetype.AbsenceDescription }}
                </mat-checkbox>
              </td>
            </ng-container>



            <!-- Column for 1st Approver -->
            <ng-container matColumnDef="firstapprover">
              <th style="width:170px;" mat-header-cell *matHeaderCellDef> 1st Approver </th>
              <td mat-cell *matCellDef="let absencetype">
                <mat-select [(value)]="absencetype.Approver1UserID"
                            [disabled]="absencetype.UserIsAllowed == 0">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let approver of dataApprovers" [value]="approver.UserId">
                    <div>{{ approver.DisplayName }}</div>
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>

            <!-- Column for 2nd Approver -->
            <ng-container matColumnDef="secondapprover">
              <th style="width:170px;" mat-header-cell *matHeaderCellDef> 2nd Approver </th>
              <td mat-cell *matCellDef="let absencetype">
                <mat-select [(value)]="absencetype.Approver2UserID"
                            [disabled]="absencetype.UserIsAllowed == 0">
                  <mat-option>None</mat-option>
                  <mat-option *ngFor="let approver of dataApprovers" [value]="approver.UserId">
                    {{ approver.DisplayName }}
                  </mat-option>
                </mat-select>
              </td>
            </ng-container>

            <!-- Column for Can Be Notified -->
            <ng-container matColumnDef="isNotifier">
              <th mat-header-cell *matHeaderCellDef> Can Be Notified </th>
              <td mat-cell *matCellDef="let absencetype">
                <mat-checkbox [(ngModel)]="absencetype.isNotifier"
                              [checked]="absencetype.isNotifier === 1"
                              [disabled]="absencetype.UserIsAllowed == 0">
                </mat-checkbox>
              </td>
            </ng-container>





            <!-- Header and Row for the table -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>


        </mat-tab>





    </mat-tab-group>


      <div mat-dialog-actions class="buttons">
        <div class="dlgbtn" mat-button (click)="saveData()"><span class="material-symbols-outlined">save</span>Save</div>
        <div class="dlgbtn" mat-button (click)="dialogRef.closeAll()"><span class="material-symbols-outlined">close</span>Cancel</div>
      </div>



</div>




</div>


