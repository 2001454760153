import { Injectable } from '@angular/core';
import { DataService } from './data.service';


@Injectable({
  providedIn: 'root'
})
export class GlobalService {


  constructor(private dataService: DataService) { }


  dataPeriod: any[] = [];


  GetSystemData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.dataService.dbGetPeriod().subscribe({
        next: (response) => {
          this.dataPeriod = response.dsData;
          resolve(); // Resolve when the data is successfully retrieved
        },
        error: (error) => {
          reject(error); // Reject in case of an error
        }
      });
    });
  }




}
