

<div style="padding:20px;" class="movable-dialog" cdkDrag cdkDragRootElement=".cdk-overlay-pane">


  <div mat-dialog-content class="dialog-content">

    <div class="headwrap" [ngStyle]="{ 'background-color': AbsenceBackColour, 'color': AbsenceForeColour }">
      <div>{{ AbsenceTitle }}</div>
    </div>
  

    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput [(ngModel)]="DisplayName" disabled>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Start Date</mat-label>
      <input matInput [(ngModel)]="StartDateTime" disabled>
    </mat-form-field>

    <mat-form-field>
      <mat-label>End Date</mat-label>
      <input matInput [(ngModel)]="EndDateTime" disabled>
    </mat-form-field>

    <mat-form-field>
      <mat-label>User Notes</mat-label>
      <input matInput [(ngModel)]="RequestDescription" disabled>
    </mat-form-field>

    <div style="display: flex; justify-content: center;">
      <mat-button-toggle-group [(ngModel)]="RequestStatusID" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle value="2">Approve</mat-button-toggle>
        <mat-button-toggle value="3">Decline</mat-button-toggle>
        <mat-button-toggle value="4">Cancel</mat-button-toggle>
      </mat-button-toggle-group>
    </div>


    <mat-form-field>
      <mat-label>Response To User</mat-label>
      <input matInput [(ngModel)]="RequestResponseNotes">
    </mat-form-field>

    <hr />

    <div mat-dialog-actions class="buttons">
      <div class="dlgbtn" mat-button (click)="saveData()"><span class="material-symbols-outlined">Save</span>Submit</div>
      <div class="dlgbtn" mat-button (click)="dialogRef.closeAll()"><span class="material-symbols-outlined">close</span>Cancel</div>
    </div>

  </div>




</div>
