<h1>User Maintenance</h1>


<div class="scrollable-content2">
  <table mat-table [dataSource]="dataGetUsers" class="mat-elevation-z8">

    <ng-container matColumnDef="DisplayName">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.DisplayName}} </td>
    </ng-container>

    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
    </ng-container>

    <ng-container matColumnDef="Department">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Department </th>
      <td mat-cell *matCellDef="let element"> {{element.DeptDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="Balance2024">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Balance 2024 </th>
      <td mat-cell *matCellDef="let element"> {{element.Balance2024}} </td>
    </ng-container>

    <ng-container matColumnDef="Balance2025">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Balance 2025 </th>
      <td mat-cell *matCellDef="let element"> {{element.Balance2025}} </td>
    </ng-container>


    <ng-container matColumnDef="IsAdmin">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Is Admin </th>
      <td mat-cell *matCellDef="let element"> {{element.IsAdmin}} </td>
    </ng-container>

    <ng-container matColumnDef="IsApprover">
      <th mat-header-cell *matHeaderCellDef class="sticky-header"> Is Approver </th>
      <td mat-cell *matCellDef="let element"> {{element.IsApprover}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr style="height: 34px;" mat-row (click)="onRowClick(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="250" [pageSizeOptions]="[20, 250, 500]" showFirstLastButtons></mat-paginator>

</div>
