import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Custom validator to check if password and confirm password match.
 */
export function PasswordMatchValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmpassword')?.value;

    // Return an error if passwords do not match
    return password && confirmPassword && password !== confirmPassword
      ? { passwordMismatch: true } // Return the error object
      : null; // Return null if no error
  };
}
